import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Container, Row, Col, Button, Dropdown, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import styled from "styled-components";
import isEqual from "lodash/isEqual";
import Loader from "../Loader/Loader";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import moment from "moment";
import ConfirmationModal from "../Common/ConfirmationModal";
import CustomContentAlert, { findBySearch, tableHeightScrollBars, scroolToTop } from "../Helper/DesignHelper";
import ModalLoader from "../Loader/ModalLoader";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isMobile } from "react-device-detect";
import {
  fetchCurrentPermits,
  getReceiptPDF,
  pdfSendToMail,
  activateDeactivateAutoRenewForPermit,
  activateDeactivateAutoCancelForPermit,
  cancelAndRefundPaidPermit,
  get_all_properties,
} from "../../Actions/ParkingPermitActions/actions";
import { saveAs } from "file-saver";

import { APIResponseSuccess } from "../../Actions/APIResponseActions/action";
import { buttonRow, checkBoxRow, customRow, overlayTriggerRow } from "../Common/Rows";
import PropertDetails from "../PropertyDetail/PropertySelection";
import SendInvoiceModal from "../Modal/SendInvoiceModal";
import EditCurrentPermit from "../Modal/EditCurrentPermit";
let PageObject = {};
class ParkingPermitActivePermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _currentPermits: [],
      loader: this.props?.loader,
      deactivateLoader: false,
      filterdPermits: [],
      searchedPermits: [],
      permitType: "all",
      status: "current",
      showError: true,
      suite_id: "ParkingPermit",
      propertyId: this.props?.login_info?.id,
      user_type: this.props?.user_type,
      searchValue: "",
      isModalOpen: false,
      Id: "",
      call_when_here: "",
      show_modal: false,
      confirmDelete: false,
      show_alert: false,
      show_error_alert: true,
      api_success_message: "",
      api_error_message: "",
      arrAfter: [],
      temp_deactivation: true,
      permit_class: "",
      page: 1,
      Norow: 100,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      count: 0,
      findMore: false,
      beforeState: "",
      timezone: this.props?.timezone,
      property: this.props?.property,
      standard: "",
      visitor_current: "",
      visitor_future: "",
      finalTableHeight: 0,
      exportLink: false,
      listHeading: "current",
      show_cancel_modal: false,
      selectedPermitId: "",
      isCancel: false,
      selectedPropertyObj: {
        value: this.props?.login_info?.id,
        label: this.props?.login_info?.property_name,
      },
      detectedDevice: "",
      childcheckbox: true,
      modelOpen: false,
    };

    this.customStyles = {
      data: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    const actionButton = (row) => {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="Primary" className="btn-xs" id="dropdown-basic" disabled={row.status.toLowerCase() === "inactive"}>
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu className="py-0">
            <Dropdown.Item href="" onClick={() => this.modelOpen(row)} className="text-dark">
              Edit Permit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    this.columns = [
      {
        name: "Domain",
        sortable: true,
        minWidth: "120px",
        maxWidth: "250px",
        selector: (row) => row?.properties?.domain,
        cell: (row) =>
          customRow({
            value: row?.properties?.domain,
          }),
      },
      {
        name: "LPN",
        sortable: true,
        minWidth: "120px",
        maxWidth: "250px",
        selector: (row) => row?.lpn,
        cell: (row) =>
          customRow({
            value: row?.lpn ? row?.lpn : "-",
            className: row.groupid && row.permit_class === "PermitPackage" ? "lpnIcon" : "",
            primary_permit: row.primary_permit,
            row,
          }),
      },
      {
        name: "Begin Time",
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.begin_time,
        cell: (row) =>
          customRow({
            value: row?.begin_time ? row?.begin_time : "-",
          }),
      },
      {
        name: "Expiry Time",
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.expire_time,
        cell: (row) =>
          customRow({
            value: row?.expire_time ? row?.expire_time : "-",
          }),
      },
      {
        name: "Unit Id",
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row?.suite_id ? row?.suite_id : "-",
          }),
        selector: (row) => row?.suite_id,
      },
      {
        name: "Lot",
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row?.lot_name ? row?.lot_name : "-",
          }),
        selector: (row) => row?.lot_name,
      },
      {
        name: "Package",
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row?.package_name ? row?.package_name : "-",
          }),
        selector: (row) => row?.package_name,
      },
      {
        name: "Permit Class",
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row?.permit_class ? row?.permit_class : "-",
          }),
        selector: (row) => row?.permit_class,
      },
      {
        name: "First Name",
        sortable: true,
        minWidth: "140px",
        maxWidth: "250px",
        cell: (row) => overlayTriggerRow({ value: row?.first_name ? row?.first_name : "-" }),
        selector: (row) => row?.first_name,
      },
      {
        name: "Last Name",
        sortable: true,
        minWidth: "140px",
        maxWidth: "250px",
        cell: (row) => overlayTriggerRow({ value: row?.last_name }),
        selector: (row) => row.last_name,
      },
      {
        name: "Phone",
        sortable: true,
        minWidth: "120px",
        maxWidth: "250px",
        cell: (row) =>
          customRow({
            value: row?.phone ? (row?.country_code || "") + row?.phone?.substring(0, 10) : "-",
            className: "text-nowrap",
          }),
        selector: (row) => row?.phone,
      },
      {
        name: "View Parking Receipt",
        sortable: true,
        width: "160px",
        cell: (row) =>
          buttonRow({
            active: row?.active,
            primary_permit: row?.primary_permit,
            permit_type: row?.permit_type,
            value: "View Receipt",
            className: "btn-blue-bg",
            variant: "primary",
            size: "xs",
            onClick: () => this.handleShowReceiptDetails(row),
            deactivated: row?.deactivated,
          }),
        selector: (row) => row?.primary_permit,
      },
      {
        name: "Status",
        sortable: true,
        minWidth: "80px",
        maxWidth: "80px",
        cell: (row) =>
          customRow({
            value: row?.status,
            className: row.status.toLowerCase() === "active" ? "text-nowrap text-green" : "text-nowrap text-danger",
          }),
        selector: (row) => row?.phone,
      },
      {
        name: "Lpn Edit Count",
        width: "100px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row.edit_count,
            className: "text-nowrap",
          }),
        selector: (row) => row.edit_count,
      },
      {
        name: "Paid Status",
        width: "100px",
        sortable: true,
        cell: (row) =>
          customRow({
            value: row.paid_status,
            className: "text-nowrap",
          }),
        selector: (row) => row.paid_status,
      },
      {
        name: "Action",
        width: "100px",
        cell: actionButton,
      },
    ];
    this.columns.splice(10, 0, {
      name: "Cancel",
      sortable: true,
      center: true,
      cell: (row) =>
        buttonRow({
          active: row.active,
          primary_permit: row?.primary_permit,
          permit_type: row?.permit_type,
          value: "Cancel",
          className: "btn-fill text-nowrap",
          variant: "danger",
          size: "xs",
          onClick: () => this.handleShowCancelPermitModal(row.id),
          allow_cancellation: row?.allow_cancellation,
        }),
      selector: (row) => row?.auto_renew,
    });
    this.columns.splice(11, 0, {
      name: "Auto Renew",
      sortable: true,
      center: true,
      cell: (row) =>
        checkBoxRow({
          active: row.active,
          primary_permit: row?.primary_permit,
          value: row?.auto_renew,
          onChange: () => {
            this.handleAutoRenewActivateDeactivate(row);
          },
          disabled: row.permit_type === "usage_permits" && row.paid_permit_options.expire_in_days != "end_of_month" ? true : false,
          allow_cancellation: row?.allow_cancellation,
        }),
      selector: (row) => row?.auto_renew,
    });
    this.columns.splice(12, 0, {
      name: "Auto Cancel When Waitlist is Cleared",
      sortable: true,
      center: true,
      width: "230px",
      cell: (row) =>
        checkBoxRow({
          active: row.active,
          primary_permit: row?.primary_permit,
          value: row?.auto_cancel,
          onChange: () => {
            this.handleAutoCancelActivateDeactivate(row);
          },
          disabled: row.permit_type === "usage_permits" || row.groupid ? true : false,
          allow_cancellation: row?.allow_cancellation,
        }),
      selector: (row) => row?.auto_cancel,
    });
  }

  DataRow = (label, value, tooltip) => (
    <Row>
      <Col xs="4">
        <b>{label}</b>
      </Col>
      <Col xs="8">
        {tooltip ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-${label}`}>{value}</Tooltip>}>
            <span>{value}</span>
          </OverlayTrigger>
        ) : (
          <span>{value}</span>
        )}
      </Col>
    </Row>
  );

  expandedComponent = ({ data }) => {
    const getValue = (field, defaultValue = "-") => (data[field] ? data[field] : defaultValue);
    const formatDate = (date) => (moment(date).isValid() ? date : "-");

    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          <Col>{this.DataRow("Email", getValue("email"), true)}</Col>
          <Col>{this.DataRow("Fee", getValue("fee"), false)}</Col>
          <Col>{this.DataRow("Paid By", getValue("payment_type"), true)}</Col>
        </Row>

        <Row className="expandedView mb-1">
          <Col className="min-w-150px">{this.DataRow("Create Time", getValue("permit_created_at"), true)}</Col>
          <Col>{this.DataRow("Invited Suite", getValue("suite_id"), true)}</Col>
          <Col>{this.DataRow("Discount", getValue("discount_amount"), true)}</Col>
        </Row>

        <Row className="expandedView mb-1">
          <Col>{this.DataRow("Group Id", getValue("groupid"), true)}</Col>
          <Col>{this.DataRow("Lot Name", getValue("lot_name"), true)}</Col>
          <Col>{this.DataRow("Paid Amount", getValue("paid_amount"), true)}</Col>
        </Row>

        <Row className="expandedView mb-1">
          {data.groupid && data.permit_class === "PermitPackage" && (
            <>
              <Col>{this.DataRow("Group LPN", data.lpn_options ? data.lpn_options.join(", ") : "-", true)}</Col>
              <Col>{this.DataRow("Group Name", data.permit_share_group ? data.permit_share_group.group_name : "-", true)}</Col>
              <Col>{this.DataRow("Max Allowed In", data.permit_share_group ? data.permit_share_group.max_allowed_in : "-", true)}</Col>
            </>
          )}
        </Row>

        <Row className="expandedView mb-1">
          {data.permit_class === "PermitPackage" && data.permit_type === "usage_permits" && (
            <>
              <Col>{this.DataRow("Uses", getValue("usage_count"), true)}</Col>
              <Col>{this.DataRow("Used", data.used_count ? `${data.used_count} of ${data.usage_count}` : "-", true)}</Col>
              <Col></Col>
            </>
          )}
        </Row>

        <Row className="expandedView mb-1">
          <Col>{this.DataRow("Waitlist Permit", data.notes?.toString().trim() === "waitlist" ? "1" : "0", true)}</Col>
          <Col>{this.DataRow("Deactivated At", formatDate(data.deactivated_at), true)}</Col>
          <Col>{this.DataRow("Refunded Amount", getValue("refunded_amount"), true)}</Col>
        </Row>

        <Row className="expandedView mb-1">
          <Col>{this.DataRow("Stall Id", getValue("stall_id"), true)}</Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </div>
    );
  };

  async componentDidMount() {
    const { page, Norow, searchValue, selectedColumn, sort_direction, permitType, status, selectedPropertyObj, childcheckbox } = this.state;

    const { info } = JSON.parse(localStorage.getItem("DriverUser"));
    const suite_id_ = "ParkingPermit";
    this.setState(
      {
        loader: true,
        suite_id: suite_id_,
        user_type: info?.user_type,
        PropertyId: info?.property_id,
        selectedPropertyObj: {
          value: this.props?.login_info?.id,
          label: this.props?.login_info?.property_name,
        },
      },
      async () => {
        this.setState({ beforeState: this.state.permit_class });
        const { user_type, PropertyId } = this.state;
        let pageobj = {
          suite_id: suite_id_,
          user_type,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value || PropertyId;
        } else {
          pageobj.PropertyId = this.props.property_id || PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        PageObject = pageobj;
        await this.props.fetchCurrentPermits(pageobj);
        await this.props.get_all_properties(PropertyId, false, "driver");

        const { currentPermitData } = this.handleGroupPermit(this.props.currentPermits.data);

        this.setState({
          _currentPermits: currentPermitData,
          count: currentPermitData.length,
          filterdPermits: currentPermitData,
          loader: false,
          showError: this.props.api_response,
          show_alert: false,
        });
      },
    );
    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    const height = tableHeightScrollBars();
    const detectedDevice = this.getMobileOperatingSystem();

    this.setState({
      finalTableHeight: height,
      detectedDevice: detectedDevice,
    });
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/user/export_csv/${this.state.property?.id}/permits`,
        newPermitUrl: `${exportUrl}/api/v1/user/export_csv/${this.state.property?.id}/permits`,
      });
    }
  }

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  componentDidUpdate(prevProps) {
    const { selectedPropertyObj, childcheckbox } = this.state;

    if (!isEqual(prevProps.currentPermits, this.props.currentPermits)) {
      const { currentPermitData } = this.handleGroupPermit(this.props.currentPermits.data);

      this.setState(
        {
          _currentPermits: currentPermitData,
          filterdPermits: currentPermitData,
          count: currentPermitData.length,
          showError: this.props.api_response,
          temp_deactivation: true,
          show_alert: true,
          api_success_message: this.props.api_success_message,
          permitType: "all",
          status: "current",
          suite_id: "ParkingPermit",
          propertyId: this.props?.login_info?.id,
          selectedPropertyObj: {
            value: selectedPropertyObj ? selectedPropertyObj.value : this.props?.login_info?.id,
            label: selectedPropertyObj ? selectedPropertyObj.label : this.props?.login_info?.property_name,
          },
          childcheckbox: childcheckbox,
        },
        () => {
          if (this.state.filterdPermits.length === 0) {
            this.setState({ page: 1 }, async () => {
              this.setState({
                filterdPermits: currentPermitData,
                count: currentPermitData.length,
              });
            });
          }
          if (this.state.searchValue?.length) {
            this.handleSearchChange(this.state.searchValue);
          }
          setTimeout(() => {
            this.setState({
              show_alert: false,
            });
          }, 4000);
        },
      );
    }
  }

  handleCheckBox = () => {
    this.setState({
      childcheckbox: !this.state.childcheckbox,
    });
  };

  modelOpen = (row) => {
    this.setState({
      modelOpen: !this.state.modelOpen,
      permitData: row,
    });
  };

  CloseModal() {
    this.setState({ modelOpen: false });
  }

  closeModal = () => {
    this.setState({
      showReceiptModal: false,
    });
  };

  handleGroupPermit = (allcurrentPermitData) => {
    const currentPermitData = allcurrentPermitData ? JSON.parse(JSON.stringify(allcurrentPermitData)) : [];
    const group_data_tobe_join = currentPermitData.filter((item) => {
      if (item?.groupid) {
        return { ...item };
      }
    });

    currentPermitData.forEach((item) => {
      if (item?.groupid && item?.permit_class === "PermitPackage") {
        const l = group_data_tobe_join.filter((item2) => item2?.groupid === item?.groupid && item?.id !== item2?.id);
        const lpns = [];
        l.forEach((lpn) => {
          lpns.push(lpn.lpn);
        });
        item.lpn_options = lpns;
      }
    });
    return { currentPermitData };
  };

  ticketDataPdf = async (sendMailData) => {
    sendMailData.language = "en";
    console.log("sendMailData::::::::::::", sendMailData);
    await this.props.pdfSendToMail(sendMailData);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isSendMailModalOpen: false,
        showReceiptModal: false,
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
      }, 4000);
    }
  };

  handleShowReceiptDetails = async (row) => {
    row.language = "en";
    console.log("this is the row", row);
    this.setState(
      {
        showReceiptModal: true,
        pdfLoader: true,
      },
      async () => {
        if (row) {
          const data = await this.props.getReceiptPDF(row);
          if (this.props.api_response || data) {
            this.setState({
              pdfUrl: data,
              selectedReceiptId: row.id,
              propertyId: row.property_id,
              pdfLoader: false,
            });
          }
        }
      },
    );
  };

  exportUrlCall = () => {
    const unix_time = Math.round(new Date().getTime() / 1000);
    const current_time = unix_time - 14400;
    const { permitType, status, searchValue, exportUrl } = this.state;
    const newPermitUrl = `${exportUrl}/?permit_type=${permitType}&status=${status}&search=${searchValue}&current_time=${current_time}`;
    this.setState({
      newPermitUrl: newPermitUrl,
    });
  };

  handleShowCancelPermitModal = (id) => {
    this.setState({
      show_cancel_modal: !this.state.show_cancel_modal,
      selectedPermitId: id,
    });
  };

  handleCancelPermit = async () => {
    this.setState({
      deactivateLoader: true,
      isCancel: true,
    });
    const { page, Norow, selectedColumn, sort_direction, permitType, status, selectedPropertyObj, PropertyId, childcheckbox } = this.state;
    const pageObj = {
      Norow: Norow,
      page: page,
      permitType: permitType,
      searchValue: "",
      selectedColumn: selectedColumn,
      sort_direction: sort_direction,
      status: status,
      suite_id: this.state.suite_id ? this.state.suite_id : "ParkingPermit",
      userType: this.state.user_type,
    };

    if (Object.keys(selectedPropertyObj).length) {
      pageObj.PropertyId = selectedPropertyObj.value || PropertyId;
    } else {
      pageObj.PropertyId = this.props.property_id || PropertyId;
    }
    if (childcheckbox) {
      pageObj.childcheckbox = childcheckbox;
    }

    await this.props.cancelAndRefundPaidPermit(this.state.selectedPermitId, pageObj, true);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        selectedPermitId: "",
        show_cancel_modal: !this.state.show_cancel_modal,
        deactivateLoader: false,
        isCancel: false,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
        selectedPermitId: "",
        show_cancel_modal: !this.state.show_cancel_modal,
        deactivateLoader: false,
      });
    }
  };

  handleSearchChange = ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    const columnList = [
      "lpn",
      "begin_time",
      "domain",
      "expire_time",
      "suite_id",
      "permit_class",
      "first_name",
      "last_name",
      "phone",
      "email",
      "permit_created_at",
      "permit_tag",
      "usage",
      "stall_id",
      "lot_name",
      "card_number",
      "package_name",
      "visitor_phone",
    ];
    this.setState({ searchValue: val }, () => {
      if (this.state.searchValue.length) {
        this.exportUrlCall();
        const { searchValue } = this.state;
        if (!this.state.findMore) {
          this.setState(
            {
              pageChanged: true,
              filterdPermits: findBySearch(searchValue, this.state._currentPermits, this.state._currentPermits, columnList, this.props.timezone),
            },
            () => {
              this.setState({ count: this.state.filterdPermits.length });
            },
          );
        }
        this.setState({
          searchValue,
        });
      } else {
        this.setState({
          _currentPermits: this.state._currentPermits,
          count: this.state._currentPermits.length,
          filterdPermits: [],
        });
      }
    });
  };

  sendMailClick = (e, emailToSend) => {
    e.preventDefault();
    const type = "pdf";
    const sendMailData = {
      type,
      emailToSend,
      permitId: this.state.selectedReceiptId,
      propertyId: this.state.propertyId,
    };
    this.ticketDataPdf(sendMailData);
  };

  handleAutoRenewActivateDeactivate = async (row) => {
    await this.props.activateDeactivateAutoRenewForPermit(row);
    this.setState({
      Id: row.id,
    });
    this.handleAlertMessage(row);
  };

  handleAlertMessage = (row) => {
    this.setState({
      Id: row.id,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
    }
    setTimeout(() => {
      this.setState({ show_alert: false, show_error_alert: true });
    }, 4000);
  };

  handleAutoCancelActivateDeactivate = async (row) => {
    await this.props.activateDeactivateAutoCancelForPermit(row);
    this.handleAlertMessage(row);
  };

  datatableHandle = (
    searchValue,
    currentPermits,
    filterdPermits,
    // pageChanged
  ) => {
    return (
      <DataTable
        data={searchValue?.length ? filterdPermits : currentPermits}
        columns={this.columns}
        customStyles={this.customStyles}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        responsive={true}
        // subHeader
        onChangePage={scroolToTop}
        defaultSortAsc={false}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleSearch = async () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        suite_id,
        user_type,
        selectedPropertyObj,
        childcheckbox,
        PropertyId,
      } = this.state;
      const pageObj = {
        suite_id: suite_id ? suite_id : "ParkingPermit",
        user_type,
        permitType,
        status,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        childcheckbox,
      };
      pageObj.exportLink = false;
      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      await this.props.fetchCurrentPermits(pageObj);
      this.setState({ loader: false, listHeading: status, show_alert: false });
      document.body.classList.remove("foc-open");
    });
  };

  exportPemrits = () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        suite_id,
        user_type,
        selectedPropertyObj,
        PropertyId,
        childcheckbox,
      } = this.state;
      const pageObj = {
        suite_id: suite_id ? suite_id : "ParkingPermit",
        user_type,
        permitType,
        status,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      pageObj.exportLink = true;
      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageObj.childcheckbox = childcheckbox;
      }
      const bufferData = await this.props.fetchCurrentPermits(pageObj);
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `PERMITS-${currentTimeDate}.xls`;
      if (bufferData) {
        saveAs(
          new Blob([bufferData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }

      this.setState({ loader: false });
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    console.log("numPages", numPages);
  };

  render() {
    const {
      status,
      loader,
      // isModalOpen,
      // confirmDelete,
      show_alert,
      show_error_alert,
      api_success_message,
      api_error_message,
      searchValue,
      filterdPermits,
      _currentPermits,
      page,
      Norow,
      pageChanged,
      listHeading,
      show_cancel_modal,
      isCancel,
      selectedPropertyObj,
      childcheckbox,
      modelOpen,
      permitData,
    } = this.state;
    return (
      <div>
        <Container fluid className="p-0">
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide pb-0">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">{`List of ${listHeading} Permit`}</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              // href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Col md="12">
                            <div className="mb-2">
                              <label className="fancy-checkbox">
                                <input
                                  type="checkbox"
                                  name={"childcheckbox"}
                                  checked={childcheckbox}
                                  onChange={this.handleCheckBox}
                                  className="custom-control-input"
                                  id="childcheckbox1"
                                ></input>
                                <span>
                                  <i></i>Include Children Sites
                                </span>
                              </label>
                            </div>
                          </Col>
                          <Col md="12">
                            <PropertDetails
                              selectedPropertyObj={selectedPropertyObj}
                              changeProperty={(e) => {
                                this.setState({
                                  selectedPropertyObj: e,
                                });
                              }}
                            />
                          </Col>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-end d-flex">
                          <Button variant="info" className="h-40 btn-fill btn-sm" onClick={this.handleSearch} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header flex-column-sm sm-align-items-start">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-2 mb-md-0">{`List of ${listHeading} Permit`}</h5>
                        </div>
                        {/* <Row className="align-items-center"> */}
                        {/* </Row> */}
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader className="mt-0 mb-0" searchValue={this.state.searchValue} HandleChange={this.handleSearchChange} />
                            {this.state.filterdPermits.length || this.state._currentPermits.length > 0 ? (
                              <div className="ml-2">
                                <span onClick={this.exportPemrits} className="btn-fill btn-sm btn btn-info btn-medium ml-2">
                                  Export
                                </span>
                                {/* </a> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader || this.props.loader ? (
                    <Loader />
                  ) : (
                    <>
                      {/* {(_currentPermits || filterdPermits) && (
                        <> */}
                      <div className="citation-list-table">{this.datatableHandle(searchValue, _currentPermits, filterdPermits, page, Norow, pageChanged)}</div>
                      {/* </>
                      )} */}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {modelOpen && (
          <EditCurrentPermit closeModal={this.CloseModal.bind(this)} modelOpen={modelOpen} permitData={permitData} status={status} PageObject={PageObject} />
        )}

        {show_cancel_modal ? (
          <ConfirmationModal
            onOpen={show_cancel_modal}
            onConfirm={this.handleCancelPermit}
            message="Are You sure you want to cancel permit?"
            onClose={this.handleShowCancelPermitModal}
            loading={false}
            title={"Cancel Permit"}
            isCanceldisabled={isCancel}
          />
        ) : null}

        {show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {!show_error_alert && api_error_message && <CustomContentAlert delay={10000} message={api_error_message} className="toast-error" />}
        {
          <Modal show={this.state.showReceiptModal} onHide={this.closeModal}>
            <Modal.Header className="no-border">
              <Modal.Title as="h4" className="m-0">
                <h4 className="m-0 font-weight-bold">Parking Receipt</h4>
              </Modal.Title>
              <Button title={"Close"} type="button" onClick={this.closeModal} className="close-btn py-0" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove"></i>
              </Button>
            </Modal.Header>
            <Modal.Body className="modal-data-body">
              {this.state.pdfLoader ? (
                <PdfContainer>
                  <ModalLoader />
                </PdfContainer>
              ) : (
                <div>
                  <>
                    {this.state.pdfUrl && !isMobile && (
                      <>
                        <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />
                      </>
                    )}
                    {this.state.pdfUrl && isMobile && (
                      <>
                        {this.state.detectedDevice === "iOS" && <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />}
                        {this.state.detectedDevice !== "iOS" && (
                          <div>
                            <Document file={this.state.pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
                              <Page pageNumber={1} />
                            </Document>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <a id="pdf" href={this.state.pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
                pdf
              </a>
              <div className="d-flex w-100 justify-content-md-center flex-wrap">
                <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                  <Dropdown.Toggle>
                    <i className="fa fa-download" aria-hidden="true"></i> Save as
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        document.getElementById("pdf").click();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                  <Dropdown.Toggle>
                    {/* <img className="" src={email} width={18} /> */}
                    Send mail as
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        this.setState({
                          isSendMailModalOpen: true,
                          showReceiptModal: false,
                        })
                      }
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button id="ignoreButton3" className="btn-fill mx-2 mt-2" type="button" variant="secondary" onClick={this.closeModal}>
                  {/* <img className="ml-0 mr-1" src={cancel} width={18} /> */}
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        }
        {this.state.isSendMailModalOpen && (
          <SendInvoiceModal
            closeModal={() =>
              this.setState({
                isSendMailModalOpen: false,
              })
            }
            ticketDataPdf={this.sendMailClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ ParkingPermitDetails, APIResponseDetails, UserProfileDetails, Logindetails }) => {
  const { currentPermits, loader } = ParkingPermitDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { suite_id, property_id, user_type, timezone, property, permission_id } = UserProfileDetails;
  const { backgroundColor, login_info } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    currentPermits,
    api_response,
    api_error_message,
    suite_id,
    property_id,
    user_type,
    api_success_message,
    timezone,
    property,
    main_body,
    permission_id,
    loader,
    login_info,
  };
};

const mapStateToDispatch = {
  fetchCurrentPermits: fetchCurrentPermits,
  APIResponseSuccess: APIResponseSuccess,
  // setDomainHeader: SuperAdminAction.setDomainHeader,
  getReceiptPDF: getReceiptPDF,
  pdfSendToMail: pdfSendToMail,
  activateDeactivateAutoRenewForPermit: activateDeactivateAutoRenewForPermit,
  activateDeactivateAutoCancelForPermit: activateDeactivateAutoCancelForPermit,
  cancelAndRefundPaidPermit: cancelAndRefundPaidPermit,
  get_all_properties: get_all_properties,
};

export default connect(mapStateToProps, mapStateToDispatch)(ParkingPermitActivePermit);

const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;
const PdfContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
`;
