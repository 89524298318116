import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Card, Modal, Button, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllLotPermitList,
  createDriverPaidPermit,
  getDriverCreditCardList,
  get_all_properties,
  DeleteCreditCardDetails,
  getAllPermitOptionsByLots,
} from "../../Actions/ParkingPermitActions/actions";
import DriverPermitCreate from "../DriverPermitCreate/DriverPermitCreate";

import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
import moment from "moment-timezone";
import { isEmailValid, removeSpecialChar } from "../Helper/ValidationHelper";

import { getUserProfileDetails } from "../../selectors/LoginDetails";
import _ from "lodash";
import useProperty from "../../../hooks/property";
import DeleteCardModal from "../Modal/DeleteCardModal";
import CustomContentAlert from "../Helper/DesignHelper";
import { getAPIResponse } from "../../selectors/APIResponseReducer";

const initialFormData = {
  domain: "",
  lotList: [],
  max_amount: "",
  usage_count: "",
  cost: "",
  daily_amount: "",
  user_id: "",
  driver_first_name: "",
  driver_last_name: "",
  email: "",
  country_code: "",
  phone: "",
  // password: "",
  car_desription: "",
  lpn: "",
  auto_renew: true,
  auto_cancel: true,
  terms_and_condition: false,
  card_number: "",
  card_expiry: "",
  card_cvv: "",
  card_name: "",
  startDate: "",
  permit_type: "",
  country_code_list: [
    { text: "+1", value: "+1" },
    { text: "+91", value: "+91" },
    { text: "+44", value: "+44" },
    { text: "+61", value: "+61" },
  ],
  // passwordVisible: false,
  is_form_valid: "",
  errors: {},
  disable_card_options: false,
  isCardEdit: false,
  Norow: 100,
  page: 1,
  permitType: "all",
  searchValue: "",
  selectedColumn: "created_at",
  sort_direction: "DESC",
  suite_id: "ParkingPermit",
  userType: "driver",
  lpn_car_options: [],
  card_details_id: "",
  startDateDisable: false,
  stall_id: "",
  original_stall_id: null,
};
let initialObj = {};
const DriverPermitModal = (props) => {
  const { activeTab } = props;
  const { properties } = useProperty();
  const propertyObj = properties?.length === 1 ? properties[0] : { value: "", label: "--Select Property--" };

  const [formData, setFormData] = useState({
    ...initialFormData,
    status: activeTab === "1" ? "current" : "future",
  });
  const [selectedProperties, setselectedProperties] = useState("");
  const [selectedPropertyObj, setselectedPropertyObj] = useState(propertyObj);
  const [lpn_btn_status, setLpn_btn_status] = useState(true);
  const [show_delete_card_model, setshow_delete_card_model] = useState(false);
  const [isDeleteCard, setisDeleteCard] = useState(false);

  const dispatch = useDispatch();

  const userProfileDetail = useSelector(getUserProfileDetails);

  const lot_package = useSelector((state) => state.ParkingPermitDetails.driver_package);

  const { api_success_message, api_error_message } = useSelector(getAPIResponse);

  const property_id = userProfileDetail?.property_id;
  const user_id = userProfileDetail?.id;
  const username = userProfileDetail?.username;
  const timezone = userProfileDetail?.timezone;
  const { driver_credit_card, driver_data } = useSelector(getParkingPermitDetail);
  useEffect(() => {
    if (api_success_message === "Card Details deleted successfully!") {
      setshow_delete_card_model(false);
      dispatch(getDriverCreditCardList(user_id, property_id));
    }
  }, [api_success_message, api_error_message]);

  useEffect(() => {
    dispatch(get_all_properties(property_id, false, "driver"));
    setselectedProperties(selectedPropertyObj.value ? selectedPropertyObj.value : property_id);
  }, [property_id, selectedPropertyObj]);

  useEffect(() => {
    dispatch(getDriverCreditCardList(user_id, property_id));
  }, [user_id, property_id]);

  useEffect(() => {
    if (selectedPropertyObj?.value || property_id) {
      dispatch(getAllLotPermitList(selectedPropertyObj?.value ? selectedPropertyObj?.value : property_id, false));
    }
  }, [selectedPropertyObj?.value, property_id]);

  const changeProperty = (event) => {
    setselectedPropertyObj(event);
    setselectedProperties(event.value);
    setFormData({
      ...formData,
      lot_id: "",
      package_name: "",
      stall_id: "",
      original_stall_id: "",
      lpn_car_options: [],
      lpn: "",
      car_desription: "",
    });
    const mainObj = { ...formData, lot_id: "", package_name: "", lpn_car_options: [], lpn: "", car_desription: "" };
    checkValidationsOnChange(event, mainObj);
  };

  useEffect(() => {
    if (username) {
      setFormData({
        ...formData,
        ...initialObj,
        email: username,
      });
    }
  }, [username, formData.max_amount, props, driver_credit_card]);

  useEffect(() => {
    if (formData.lpn.toString().length > 0 && formData?.lpn_car_options?.length === 0) {
      if (formData.active_permit_count >= formData.max_permit_count && formData.allow_waitlist) {
        setLpn_btn_status(true);
      } else {
        setLpn_btn_status(false);
      }
    } else if (formData.lpn_car_options.length === 1) {
      if (formData.lpn_car_options[0]?.lpn?.toString().length > 0) {
        setLpn_btn_status(false);
      } else {
        setLpn_btn_status(true);
      }
    } else {
      setLpn_btn_status(true);
    }
    if (formData.lpn_car_options.length === 0 && formData.permit_type === "monthly_permits") {
      setFormData({
        ...formData,
        auto_cancel: true,
      });
    } else {
      setFormData({
        ...formData,
        auto_cancel: false,
      });
    }
  }, [formData.lpn_car_options, formData.lpn]);

  useEffect(() => {
    console.log("driver_credit_card:111111111111", driver_credit_card);
    if (driver_credit_card && Object.keys(driver_credit_card).length > 0) {
      initialObj = {
        isCardEdit: true,
        disable_card_options: true,
        card_details_id: driver_credit_card.id,
        card_name: driver_credit_card.name,
        card_number: driver_credit_card.card_number, // `************${driver_credit_card.last_four_card_number}`,
        card_expiry: driver_credit_card.expiry_date,
      };
    } else {
      initialObj = {
        isCardEdit: false,
        // disable_card_options: false,
        card_name: "",
        card_number: "",
        disable_card_options: !formData.disable_card_options,
        card_email: "",
        card_expiry: "",
        card_cvv: "",
      };
    }
    setFormData({ ...formData, ...initialObj });
  }, [driver_credit_card]);

  const checkLpnValidation = useCallback(
    (mainObj) => {
      const allLpn = [mainObj.lpn, ...mainObj.lpn_car_options.map((item) => item.lpn)];

      const checkValueBlank = allLpn.filter((item) => item === "");
      if (checkValueBlank.length === 0 && allLpn.length <= 2) {
        setLpn_btn_status(false);
      } else {
        setLpn_btn_status(true);
      }

      const duplicateLPN = allLpn.filter((item, index) => allLpn.indexOf(item) !== index);
      if (duplicateLPN.length >= 1) {
        return { error_msg: "Duplicate Plate Number Exists", valid: false };
      } else {
        return { error_msg: "", valid: true };
      }
    },
    [formData.lpn, formData.lpn_car_options],
  );

  const handleChange = async ({ target }) => {
    let { name, value } = target;

    let newValue = !["email", "country_code"].includes(name) ? removeSpecialChar(value) : value;

    let Obj = { ...formData };

    if (["phone", "card_number", "card_cvv"].includes(name)) {
      newValue = newValue.replace(/[^0-9.]/g, "");
    }
    if (["lpn"].includes(name)) {
      newValue = newValue.toUpperCase().replace(/\s/g, "");
    }
    Obj = { ...Obj, [name]: newValue };
    const mainObj = { ...formData, ...Obj };
    setFormData(mainObj);
    checkValidationsOnChange(target, mainObj);
  };

  const card_number_validation = async (target) => {
    let is_form_valid = true;
    const errors = {};
    const cardData = !/^\d+$/.test(target.value);
    if (!cardData && target.value.length <= 16) {
      if (target.value.length > 14) {
        const validCard = await validateCardNumber(target.value);
        if (validCard) {
          errors.card_number = "";
        } else {
          is_form_valid = false;
          errors.card_number = "Invalid Card Number";
        }
      } else {
        is_form_valid = false;
        errors.card_number = "Invalid Card Number. Enter at least 16 digits.";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const card_cvv_validation = async (target) => {
    const cvvdata = !/^\d+$/.test(target.value);
    let is_form_valid = true;
    const errors = {};
    if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
      if (target.value.length >= 3) {
        errors.card_cvv = "";
      } else if (target.value.length > 1) {
        errors.card_cvv = "Invalid CVV. Enter at least 3 digits.";
        is_form_valid = false;
      } else {
        errors.card_cvv = "";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const checkValidationsOnChange = useCallback(
    async (target, mainObj) => {
      let is_form_valid = true;
      let errors = {};
      if (!target.value.toString().length) {
        is_form_valid = false;
        errors[target.name] = `${_.startCase(target.name).split("_").join(" ")} can't be empty`;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      if (target.name === "card_number") {
        const { error, valid } = await card_number_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      if (target.name === "card_cvv") {
        const { error, valid } = await card_cvv_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      if (target.name === "lot_id") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.lot_id = `Lot can't be empty`;
        } else {
          is_form_valid = true;
          errors.lot_id = "";
        }
      }
      if (target.name === "package_name") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.package_name = `Package can't be empty`;
        } else {
          is_form_valid = true;
          errors.package_name = "";
        }
      }

      if (formData.stall_type === "user_selected" && target.name === "stall_id") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.stall_id = `Stall can't be empty`;
        } else {
          is_form_valid = true;
          errors.stall_id = "";
        }
      }

      if (target.name === "lpn") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          setLpn_btn_status(true);
        } else {
          is_form_valid = checkLpnValidation(mainObj).valid;
          errors.lpn = checkLpnValidation(mainObj).error_msg;
        }
      }

      if (target.name === "lpn_options") {
        is_form_valid = checkLpnValidation(mainObj).valid;
        errors.lpn = checkLpnValidation(mainObj).error_msg;
      }

      if (target.label === "--Select Property--" && !target.value) {
        is_form_valid = false;
        errors.property_id = "Property Can't be Empty";
      } else {
        is_form_valid = true;
        errors.property_id = "";
      }
      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const getUTCWT = useCallback(() => {
    if (formData.expire_in_days === "end_of_month" || formData.expire_in_days === null) {
      let currentdate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      if (formData.startDate) {
        const date = new Date(formData.startDate);
        currentdate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      const formatEndDate = moment(currentdate).format("YYYY-MM-DD");
      const endDateWithTime = `${formatEndDate} 23:59:59`;
      const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:SS");
      const convertedExpireTime = moment.tz(formatedET, timezone).format();

      return moment.tz(convertedExpireTime, "UTC").format();
    } else {
      let expire_in = +formData.expire_in_days;
      let currentDate = new Date();

      if (formData.startDate) {
        currentDate = new Date(formData.startDate);
      }
      const endDateWithDays = moment(currentDate).add(expire_in - 1, "days");

      const formatStartDate = moment(endDateWithDays).format("YYYY-MM-DD");

      const startDateWithTime = `${formatStartDate} 23:59:59`;

      const formatedET = moment(startDateWithTime).format("YYYY-MM-DD HH:mm:SS");
      const convertedStartTime = moment.tz(formatedET, timezone).format();
      const endTime = moment.tz(convertedStartTime, "UTC").format();

      return endTime;
    }
  }, [timezone, formData.startDate, formData.expire_in_days]);

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const errors = {};
    if (name === "auto_renew") {
      setFormData({ ...formData, auto_renew: !formData.auto_renew });
    }
    if (name === "auto_cancel") {
      setFormData({ ...formData, auto_cancel: !formData.auto_cancel });
    }
    if (name === "terms_and_condition") {
      if (formData.terms_and_condition === false) {
        errors.terms_and_condition = "";
        setFormData({
          ...formData,
          is_form_valid: true,
          terms_and_condition: !formData.terms_and_condition,
          errors: { ...formData.errors, ...errors },
        });
      } else {
        setFormData({
          ...formData,
          is_form_valid: false,
          terms_and_condition: !formData.terms_and_condition,
        });
      }
    }
  };

  const handleChangeLot = ({ target }) => {
    const mainObj = { ...formData, lot_id: target.value };
    setFormData(mainObj);
    checkValidationsOnChange(target, mainObj);
  };

  const handleChangePackage = ({ target }) => {
    const mainObj = { ...formData, package_name: target.value, stall_id: "", original_stall_id: "" };
    setFormData(mainObj);
    checkValidationsOnChange(target, mainObj);
  };

  const handleChangeStall = (event) => {
    const selectedStall = event.target.selectedOptions[0];
    console.log("selectedStallOption::::::", selectedStall, selectedStall.value, selectedStall.getAttribute("id"));
    const mainObj = {
      ...formData,
      stall_id: selectedStall.value,
      original_stall_id: selectedStall.getAttribute("id"),
    };
    setFormData(mainObj);
    checkValidationsOnChange(event.target, mainObj);
  };

  const handleCalender = (date) => {
    const errors = {};
    errors.card_expiry = "";
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: date,
        errors: { ...prevState.errors, ...errors },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: "",
      }));
    }
  };

  const mainOptions = useMemo(
    () =>
      driver_data?.data
        ?.map((item) => ({
          alias: item?.lots?.alias,
          lot_id: item?.lots?.id,
        }))
        .filter((item, index) => {
          if (driver_data?.data.map((item) => item.lot_id).indexOf(item.lot_id) === index) {
            return item;
          } else {
            return false;
          }
        }),
    [driver_data],
  );

  useEffect(() => {
    if (mainOptions?.length === 1 && selectedProperties && mainOptions?.[0]?.lot_id) {
      setFormData({
        ...formData,
        lot_id: mainOptions?.[0]?.lot_id,
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
      dispatch(getAllPermitOptionsByLots(selectedProperties, mainOptions?.[0]?.lot_id, false));
    } else {
      setFormData({
        ...formData,
        lot_id: "",
        package_name: "",
        stall_id: "",
        original_stall_id: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    }
  }, [mainOptions, propertyObj.value, selectedPropertyObj.value, selectedProperties]);

  useEffect(() => {
    if (propertyObj.value) {
      setselectedPropertyObj(propertyObj);
    }
  }, [propertyObj.value]);

  useEffect(() => {
    if (lot_package?.data?.length === 1 && selectedPropertyObj.value) {
      setFormData({
        ...formData,
        lot_id: lot_package.data?.[0]?.lot_id,
        package_name: lot_package.data?.[0]?.package_name,
        max_amount: lot_package.data?.[0]?.max_amount,
        daily_amount: lot_package.data?.[0]?.daily_amount,
        paid_permit_option_id: lot_package.data?.[0]?.id,
        permit_type: lot_package.data?.[0]?.permit_type,
        active_permit_count: lot_package.data?.[0]?.active_permit_count,
        allow_waitlist: lot_package.data?.[0]?.allow_waitlist,
        cost: lot_package.data?.[0]?.cost,
        usage_count: lot_package.data?.[0]?.usage_count,
        expire_in_days: lot_package.data?.[0]?.expire_in_days,
        max_permit_count: lot_package.data?.[0]?.max_permit_count,
        stall_type: lot_package.data?.[0]?.stall_type,
        one_usage_per_day: lot_package.data?.[0]?.one_usage_per_day,
        stall_id: "",
        original_stall_id: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    } else {
      setFormData({
        ...formData,
        package_name: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    }
  }, [lot_package, selectedPropertyObj]);

  useEffect(() => {
    if (formData.lot_id && formData.package_name && driver_data && selectedProperties) {
      const findAmount = driver_data?.data?.filter((item) => item.lot_id === formData.lot_id && item.package_name === formData.package_name);
      if (findAmount) {
        let driverObj = {
          ...formData,
          max_amount: findAmount[0]?.max_amount,
          cost: findAmount[0]?.cost,
          usage_count: findAmount[0]?.usage_count,
          one_usage_per_day: findAmount[0]?.one_usage_per_day,
          expire_in_days: findAmount[0]?.expire_in_days,
          daily_amount: findAmount[0]?.daily_amount,
          paid_permit_option_id: findAmount[0]?.id,
          permit_type: findAmount[0]?.permit_type,
          active_permit_count: findAmount[0]?.active_permit_count,
          allow_waitlist: findAmount[0]?.allow_waitlist,
          max_permit_count: findAmount[0]?.max_permit_count,
          stall_type: findAmount[0]?.stall_type,
          lpn_car_options: [],
          lpn: "",
          car_description: "",
          autoRenewDisable: false,
        };
        if (findAmount[0]?.permit_type === "usage_permits") {
          if (findAmount[0]?.expire_in_days === 'end_of_month'){
          driverObj = { ...driverObj, auto_renew: false, startDateDisable: true, autoRenewDisable: false, startDate: "", auto_cancel: false };
        } else {
          if (findAmount[0]?.active_permit_count >= findAmount[0]?.max_permit_count && findAmount[0]?.allow_waitlist) {
            driverObj = { ...driverObj, auto_renew: false, autoRenewDisable: true, startDateDisable: true, startDate: "", auto_cancel: true };
          } else {
            driverObj = { ...driverObj, auto_renew: false, autoRenewDisable: true, startDateDisable: false, startDate: "", auto_cancel: true };
          }
        }
      }
        setFormData(driverObj);
      }
    }
  }, [formData.lot_id, formData.package_name, driver_data, selectedProperties]);

  const validateCardNumber = async (number) => {
    // Check if the number contains only numeric value
    // and is of between 13 to 19 digits
    const regex = /^\d{13,19}$/;
    if (!regex.test(number)) {
      return false;
    }

    return numberCheck(number);
  };

  const numberCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j === 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    // Check if it is divisible by 10 or not.
    return checksum % 10 === 0;
  };

  const handleShowDeleteCardModal = () => {
    setisDeleteCard(false);
    setshow_delete_card_model(!show_delete_card_model);
  };

  const handleDeleteCard = () => {
    setisDeleteCard(true);
    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.card_name,
      card_email: "",
      card_number: "",
      card_expiry: "",
      card_cvv: "",
      isCardEdit: false,
    }));
  };

  const deleteCardDetails = () => {
    setshow_delete_card_model(true);
    setisDeleteCard(false);
  };

  const handleDriverPermitCreate = () => {
    const {
      lot_id,
      package_name,
      driver_first_name,
      driver_last_name,
      email,
      country_code,
      phone,
      car_desription,
      auto_renew,
      auto_cancel,
      terms_and_condition,
      lpn,
      lpn_car_options,
      card_number,
      card_expiry,
      card_cvv,
      card_name,
      max_amount,
      cost,
      usage_count,
      one_usage_per_day,
      expire_in_days,
      max_permit_count,
      stall_type,
      stall_id,
      original_stall_id,
      daily_amount,
      paid_permit_option_id,
      permit_type,
      active_permit_count,
      allow_waitlist,
      isCardEdit,
      startDate,
    } = formData;
    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");
    let data;
    let valid = false;

    let UTCstartDate = "";
    if (startDate) {
      const formatStartDate = moment(startDate).format("YYYY-MM-DD");
      const startDateWithTime = `${formatStartDate} 00:00:00`;
      const formatedET = moment(startDateWithTime).format("YYYY-MM-DD HH:mm:SS");
      const convertedStartTime = moment.tz(formatedET, timezone).format();
      UTCstartDate = moment.tz(convertedStartTime, "UTC").format();
    }

    if (checkValidations()) {
      valid = true;
      data = {
        end_time: getUTCWT(),
        max_amount: max_amount,
        cost: cost,
        usage_count: usage_count,
        one_usage_per_day: one_usage_per_day,
        expire_in_days: expire_in_days,
        max_permit_count: max_permit_count,
        stall_type: stall_type,
        stall_id: stall_id,
        original_stall_id: original_stall_id,
        lot_id: lot_id,
        package_name: package_name,
        daily_amount: daily_amount,
        user_id: user_id,
        first_name: driver_first_name,
        last_name: driver_last_name,
        country_code: country_code,
        phone: phone,
        email: email,
        lpn: lpn,
        property_id: selectedProperties,
        car_description: car_desription || "",
        auto_renew: auto_renew,
        auto_cancel: auto_cancel,
        terms_and_condition: terms_and_condition,
        card_number: card_number,
        card_expiry: card_expiry_,
        card_cvv: card_cvv,
        card_name: card_name,
        paid_permit_option_id: paid_permit_option_id,
        permit_type: permit_type,
        active_permit_count: active_permit_count,
        allow_waitlist: allow_waitlist,
        is_card_edit: !isCardEdit,
        startDate: UTCstartDate,
        timezone: timezone,
        lpn_car_options: lpn_car_options,
      };
    }
    if (valid) {
      const pageObj = {
        Norow: formData.Norow,
        page: formData.page,
        permitType: formData.permitType,
        searchValue: formData.searchValue,
        selectedColumn: formData.selectedColumn,
        sort_direction: formData.sort_direction,
        status: formData.status,
        suite_id: formData.suite_id,
        userType: formData.userType,
        PropertyId: property_id,
        childcheckbox: true,
      };
      dispatch(createDriverPaidPermit(data, pageObj));
      props.closeModal();
    }
  };

  const handleAddLpnCarDescription = () => {
    setFormData({
      ...formData,
      lpn_car_options: [...formData.lpn_car_options, { lpn: "", car_description: "" }],
    });
  };

  const handleLpnCarOptions = (index, e, type) => {
    const {
      target: { value },
      target,
    } = e;
    const { lpn_car_options } = formData;
    let lpn_values;
    let car_description_values;

    const mainObj = {
      ...formData,
      lpn_car_options: lpn_car_options?.map((item, i) => {
        const obj = { ...item };
        if (i === index) {
          if (type === "lpn_options") {
            lpn_values = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
            obj.lpn = lpn_values;
          }
          if (type === "car_description_options") {
            car_description_values = value;
            obj.car_description = car_description_values;
          }
        }
        return obj;
      }),
    };

    setFormData(mainObj);

    checkValidationsOnChange(target, mainObj);
  };

  const removeLpnCarOption = (index) => {
    let is_form_valid = true;
    const errors = {};
    const mainObj = {
      ...formData,
      lpn_car_options: [...formData.lpn_car_options.filter((_x, i) => i !== index)],
    };

    is_form_valid = checkLpnValidation(mainObj).valid;
    errors.lpn = checkLpnValidation(mainObj).error_msg;

    setFormData({
      ...mainObj,
      is_form_valid: is_form_valid,
      errors: { ...errors },
    });
  };

  const cardValidation = (card_number, card_expiry, card_cvv, card_name, isCardEdit) => {
    const errors = {};
    let is_form_valid = true;
    if (!card_name?.toString().length) {
      is_form_valid = false;
      errors.card_name = `Card Holder Name can't be empty `;
    }

    if (!card_number?.toString().length) {
      is_form_valid = false;
      errors.card_number = `Card Number can't be empty `;
    }
    if (!card_expiry.toString().length) {
      is_form_valid = false;
      errors.card_expiry = `Card Expiry date can't be empty `;
    }
    if (!isCardEdit) {
      if (!card_cvv.toString().length) {
        is_form_valid = false;
        errors.card_cvv = `CVV Number can't be empty `;
      }
    }

    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const checkValidations = () => {
    const {
      driver_first_name,
      driver_last_name,
      email,
      phone,
      package_name,
      lot_id,
      lpn,
      lpn_car_options,
      card_number,
      card_expiry,
      card_cvv,
      card_name,
      terms_and_condition,
      isCardEdit,
      stall_type,
      stall_id,
    } = formData;
    let is_form_valid = true;
    let errors = {};

    const validateCardDetails = () => {
      const { error, valid } = cardValidation(card_number, card_expiry, card_cvv, card_name, isCardEdit);
      errors = { ...errors, ...error };
      return valid;
    };

    const validateField = (field, errorMsg) => {
      if (!field?.toString().length) {
        is_form_valid = false;
        errors[errorMsg.field] = errorMsg.message;
      }
    };

    const validateEmail = () => {
      if (!email.toString().length || !isEmailValid(email)) {
        is_form_valid = false;
        errors.email = `Email can't be empty or invalid`;
      }
    };

    const validatePhone = () => {
      if (!phone.toString().length || phone.toString().length !== 10) {
        is_form_valid = false;
        errors.phone = `Phone can't be empty or invalid`;
      }
    };

    const validateLpnOptions = () => {
      if (lpn_car_options.length >= 1) {
        for (const option of lpn_car_options) {
          if (!option?.lpn?.toString().length) {
            is_form_valid = false;
            errors.lpn_options = `License Plate can't be empty`;
          }
        }
      }
    };

    is_form_valid = validateCardDetails();

    validateField(selectedPropertyObj.value, { field: "property_id", message: `Property can't be empty` });
    validateField(lot_id, { field: "lot_id", message: `Parking Area can't be empty` });
    validateField(package_name, { field: "package_name", message: `Package can't be empty` });
    if (stall_type?.toString() === "user_selected") {
      validateField(stall_id, { field: "stall_id", message: `Stall can't be empty` });
    }
    validateField(driver_first_name, { field: "driver_first_name", message: `First Name can't be empty` });
    validateField(driver_last_name, { field: "driver_last_name", message: `Last Name can't be empty` });
    validateEmail();
    validatePhone();
    validateField(lpn, { field: "lpn", message: `Licence Plate Number can't be empty` });
    validateLpnOptions();
    
    if (!terms_and_condition) {
      is_form_valid = false;
      errors.terms_and_condition = `You must accept the Terms and Conditions.`;
    }

    setFormData({
      ...formData,
      is_form_valid,
      errors,
    });

    return is_form_valid;
  };

  const showError = (type) => {
    const { isCardEdit } = formData;

    let { valid, errorMsg } = subShowError(type);

    if (type === "property_id" && formData?.errors.property_id) {
      valid = false;
      errorMsg = formData.errors.property_id;
    }

    if (type === "lpn" && formData.errors?.lpn) {
      valid = false;
      errorMsg = formData.errors.lpn;
    }

    if (type === "lpn_options" && formData.errors?.lpn_options) {
      valid = false;
      errorMsg = formData.errors.lpn_options;
    }

    if (type === "card_name" && formData.errors?.card_name) {
      valid = false;
      errorMsg = formData.errors.card_name;
    }

    if (type === "card_number" && formData.errors?.card_number) {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_expiry" && formData.errors?.card_expiry) {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (!isCardEdit) {
      if (type === "card_cvv" && formData.errors?.card_cvv) {
        valid = false;
        errorMsg = formData.errors.card_cvv;
      }
    }
    
    if (type === "terms_and_condition" && formData.errors?.terms_and_condition) {
      valid = false;
      errorMsg = formData.errors.terms_and_condition;
    }

    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const handleDateChange = useCallback((target) => {
    setFormData((prevState) => ({
      ...prevState,
      startDate: target,
    }));
  }, []);

  const subShowError = (type) => {
    let valid = true;
    let errorMsg = "";
    if (type === "lot_id" && formData.errors?.lot_id) {
      valid = false;
      errorMsg = formData.errors.lot_id;
    }
    if (type === "package_name" && formData.errors?.package_name) {
      valid = false;
      errorMsg = formData.errors.package_name;
    }
    if (type === "stall_id" && formData.errors?.stall_id) {
      valid = false;
      errorMsg = formData.errors.stall_id;
    }
    if (type === "driver_first_name" && formData.errors?.driver_first_name) {
      valid = false;
      errorMsg = formData.errors.driver_first_name;
    }
    if (type === "driver_last_name" && formData.errors?.driver_last_name) {
      valid = false;
      errorMsg = formData.errors.driver_last_name;
    }
    if (type === "email" && formData.errors?.email) {
      valid = false;
      errorMsg = formData.errors.email;
    }
    if (type === "phone" && formData.errors?.phone) {
      valid = false;
      errorMsg = formData.errors.phone;
    }
    return { valid, errorMsg };
  };

  return (
    <div>
      <Modal lg={"medium"} show={props.show} onHide={props.closeModal} dialogClassName="modal-90w" fullscreen={"sm-down"} className="modal-lg">
        <Card>
          <Card.Header className="d-flex justify-content-between bg-white align-items-center">
            {/* <h4 className="m-0 font-weight-bold"> */}
            <Card.Title as="h4" className="m-0">
              Add Driver Permits
            </Card.Title>
            {/* </h4> */}
            <Button title={"Close"} type="button" onClick={props.closeModal} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger"></i>
            </Button>
          </Card.Header>
          <ModalBody>
            <DriverPermitCreate
              handleChangePackage={handleChangePackage}
              handleChangeStall={handleChangeStall}
              handleChangeLot={handleChangeLot}
              editCardDetails={editCardDetails}
              deleteCardDetails={deleteCardDetails}
              mainOptions={mainOptions}
              showError={showError}
              formData={formData}
              handleDateChange={handleDateChange}
              handleChange={handleChange}
              // TogglePasswordVisibility={TogglePasswordVisibility}
              handleCalender={handleCalender}
              handleCheckBox={handleCheckBox}
              handleAddLpnCarDescription={handleAddLpnCarDescription}
              handleLpnCarOptions={(index, e, type) => handleLpnCarOptions(index, e, type)}
              changeProperty={changeProperty}
              selectedProperties={selectedProperties}
              selectedPropertyObj={selectedPropertyObj}
              // startDateDisable={startDateDisable}
              removeLpnCarOption={(index) => removeLpnCarOption(index)}
              driver_credit_card={driver_credit_card}
              isPublic={false}
              isPublicTerms={true}
              lpn_btn_status={lpn_btn_status}
              properties={properties}
            />
          </ModalBody>
          {show_delete_card_model ? (
            <DeleteCardModal
              onOpen={show_delete_card_model}
              onConfirm={handleDeleteCard}
              message="Are You sure you want to Delete this card? It will not auto renew permits."
              onClose={handleShowDeleteCardModal}
              title={"Delete Card"}
              isDeleteCarddisabled={isDeleteCard}
              loading={false}
            />
          ) : null}

          {api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
          {api_error_message && <CustomContentAlert delay={10000} message={api_error_message} className="toast-error" />}
          <Modal.Footer>
            <Button onClick={props.closeModal}>Cancel</Button>
            <Button variant="primary" disabled={!formData.is_form_valid} onClick={handleDriverPermitCreate} className="btn-blue-bg">
              Submit
            </Button>
          </Modal.Footer>
          {/* ) : null} */}
        </Card>
      </Modal>
    </div>
  );
};

export default DriverPermitModal;
